define("discourse/plugins/discourse-calendar/discourse/initializers/add-upcoming-events-to-sidebar", ["exports", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _exports.default = {
    name: "add-upcoming-events-to-sidebar",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled && siteSettings.sidebar_show_upcoming_events) {
        (0, _pluginApi.withPluginApi)("0.8.7", api => {
          api.addCommunitySectionLink(baseSectionLink => {
            return class UpcomingEventsSectionLink extends baseSectionLink {
              constructor() {
                super(...arguments);
                _defineProperty(this, "name", "upcoming-events");
                _defineProperty(this, "route", "discourse-post-event-upcoming-events");
                _defineProperty(this, "text", _discourseI18n.default.t("discourse_calendar.discourse_post_event.upcoming_events.title"));
                _defineProperty(this, "title", _discourseI18n.default.t("discourse_calendar.discourse_post_event.upcoming_events.title"));
              }
            };
          });
        });
      }
    }
  };
});