define("discourse/plugins/discourse-calendar/discourse/connectors/discovery-list-container-top/category-events-calendar", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class CategoryEventsCalendar extends _component.default {
    static shouldRender(_1, ctx1) {
      return ctx1.siteSettings.calendar_categories_outlet === "discovery-list-container-top";
    }
  }
  _exports.default = CategoryEventsCalendar;
  _class = CategoryEventsCalendar;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div id="category-events-calendar"></div>
    
  */
  {
    "id": "sjNSeZY9",
    "block": "[[[1,\"\\n    \"],[10,0],[14,1,\"category-events-calendar\"],[12],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/connectors/discovery-list-container-top/category-events-calendar.js",
    "isStrictMode": true
  }), _class);
});