define("discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invite-user-or-group", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n
      "discourse_calendar.discourse_post_event.invite_user_or_group.title"
    }}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <form>
        <EventField>
          <EmailGroupUserChooser
            @value={{this.invitedNames}}
            @options={{hash
              fullWidthWrap=true
              includeMessageableGroups=true
              filterPlaceholder="composer.users_placeholder"
              excludeCurrentUser=true
            }}
          />
        </EventField>
      </form>
    </:body>
    <:footer>
      <DButton
        @type="button"
        class="btn-primary"
        @label="discourse_calendar.discourse_post_event.invite_user_or_group.invite"
        @action={{this.invite}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "IcCdkHp2",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"discourse_calendar.discourse_post_event.invite_user_or_group.title\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"form\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@value\",\"@options\"],[[30,0,[\"invitedNames\"]],[28,[37,4],null,[[\"fullWidthWrap\",\"includeMessageableGroups\",\"filterPlaceholder\",\"excludeCurrentUser\"],[true,true,\"composer.users_placeholder\",true]]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@type\",\"@label\",\"@action\"],[\"button\",\"discourse_calendar.discourse_post_event.invite_user_or_group.invite\",[30,0,[\"invite\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"event-field\",\"email-group-user-chooser\",\"hash\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invite-user-or-group.hbs",
    "isStrictMode": false
  });
  let PostEventInviteUserOrGroup = _exports.default = (_class = class PostEventInviteUserOrGroup extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "invitedNames", _descriptor, this);
      _initializerDefineProperty(this, "flash", _descriptor2, this);
    }
    async invite() {
      try {
        await (0, _ajax.ajax)(`/discourse-post-event/events/${this.args.model.event.id}/invite.json`, {
          data: {
            invites: this.invitedNames
          },
          type: "POST"
        });
        this.args.closeModal();
      } catch (e) {
        this.flash = (0, _ajaxError.extractError)(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "invitedNames", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "invite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "invite"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostEventInviteUserOrGroup);
});